import React, { useState, useMemo, useCallback, memo } from 'react';
import { string, func, number } from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  Select,
  Text,
  useToast,
  Heading,
  useDisclosure,
  Flex,
  MenuButton,
  Menu,
  InputGroup,
  Icon,
  MenuList,
  MenuItem,
  Image
} from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import GuestInformation from './GuestInformation';
import { BiChevronDown } from 'react-icons/bi';

import { useGuest } from '@/context/guest';
import useFormFields from '@/hooks/useFormFields';
import useRsvp from '@/usecase/use-rsvp';
import useAddNewGuest from '@/usecase/use-add-new-guest';
import useFamily from '@/hooks/useFamily';

import { BUTTON_PADDING_PROPS, BUTTON_PROPS, BG_LAYER, BG_LAYER_BORDER } from '@/constants/colors';
import { RSVP_STATUS, API_ATTEND_INFO } from '@/constants/api';
import { COUNTRY_CODES } from '@/constants/country-codes';
import {
  ERROR_PROPS,
  ERROR_TYPE,
  FORM_LABEL_PROPS,
  INPUT_COMMON_PROPS,
  PHONE_SELECT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  TYPE,
} from './types';
import {
  ENABLE_GUEST_PERSONALIZATION,
  ENABLE_GUEST_INFORMATION,
  RSVP_ENABLE_NAME,
  RSVP_ENABLE_PHONE_NUMBER,
  RSVP_ENABLE_ADDRESS,
  RSVP_ENABLE_EMAIL,
  ENABLE_QR_INVITATION,
  ENABLE_PARTNER_MODE,
  RSVP_ENABLE_ATTEND_INFO,
  ENABLE_SHIFT_TIME_RECEPTION,
} from '@/constants/feature-flags';

import { txtForm as txt } from './locales';
import { TITLE_SECTION_STYLES, CONTENT_STYLES } from '@/constants/typography'
import { ASSETS_FLOWER_3 } from '@/constants/assets'
import { styleRsvp } from './styles'

const MemoPrefixNumber = memo(function PrefixNumber(props) {
  const { selectedCountryCode, handleCountryCodeChange } = props;

  return (
    <Menu>
      <MenuButton
        {...PHONE_SELECT_COMMON_PROPS}
        as={Button}
        rightIcon={<Icon as={BiChevronDown} marginLeft="16px" />}
      >
        {`+${selectedCountryCode}`}
      </MenuButton>
      <MenuList maxHeight="200px" maxWidth="300px" overflowY="scroll" bgColor="gray.200" zIndex={3} py="0">
        {COUNTRY_CODES.map((country, index) => (
          <MenuItem
            key={country.dial_code + index}
            onClick={() => handleCountryCodeChange(country.dial_code)}
            _hover={{bgColor: "bgSecondary"}}
          >
            {`${country.name}: +${country.dial_code}`}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
});

const MemoPhoneNumberList = memo(function PhoneNumberList(props) {
  const {
    createChangeHandler,
    selectedCountryCode,
    handleCountryCodeChange,
    errorPhone,
    lang,
    value,
  } = props;

  if (!RSVP_ENABLE_PHONE_NUMBER) return null;

  return (
    <FormControl isInvalid={errorPhone} pos="relative" zIndex="10">
      <FormLabel {...FORM_LABEL_PROPS}>{txt.hp[lang]}:</FormLabel>
      <InputGroup size="md">
        <MemoPrefixNumber
          selectedCountryCode={selectedCountryCode}
          handleCountryCodeChange={handleCountryCodeChange}
        />
        <Input
          {...INPUT_COMMON_PROPS}
          borderTopLeftRadius="0px"
          borderBottomLeftRadius="0px"
          placeholder="..."
          type="number"
          value={value}
          onChange={createChangeHandler('phone_number')}
        />
      </InputGroup>
      <FormErrorMessage marginTop="4px">{errorPhone}</FormErrorMessage>
    </FormControl>
  );
});

const MemoPersonalizeList = memo(function PersonalizeList(props) {
  const { lang, totalQuota, value, onChange } = props;
  const totalFinalQuota = ENABLE_GUEST_PERSONALIZATION ? totalQuota : 2;

  if (!ENABLE_PARTNER_MODE) return null;

  return (
    <WithAnimation>
      <FormControl>
        <FormLabel {...FORM_LABEL_PROPS}>{txt.willYouBringPartner[lang]}</FormLabel>
        <Select {...SELECT_COMMON_PROPS} value={value} onChange={onChange}>
          {[...Array(totalFinalQuota).keys()].map((item, index) => {
            return (
              <option key={item} value={index + 1} style={{ color: 'black' }}>
                {index + 1}
              </option>
            );
          })}
        </Select>
        {/* SET to TRUE if you want to add some information if user bring-partner YES */}
        {value > TYPE.YES && false && (
          <FormHelperText color="mainColorText" fontSize="sm" fontStyle="italic">
            {txt.singleGuestInfo[lang]}
          </FormHelperText>
        )}
      </FormControl>
    </WithAnimation>
  );
});

MemoPersonalizeList.propTypes = {
  lang: string.isRequired,
  totalQuota: number.isRequired,
  value: number.isRequired,
  onChange: func.isRequired,
};

MemoPrefixNumber.propTypes = {
  selectedCountryCode: string,
  handleCountryCodeChange: func.isRequired,
};

MemoPhoneNumberList.propTypes = {
  createChangeHandler: func.isRequired,
  selectedCountryCode: string,
  lang: string,
  handleCountryCodeChange: func.isRequired,
  errorPhone: string,
  value: string,
};

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const isFamily = useFamily();
  const { guest, onUpdateGuest, isRegistered } = useGuest();
  const { onAddNewGuest } = useAddNewGuest();
  const { onUpdateRsvp } = useRsvp();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const filteredEventInfo = useMemo(() => {
    if (!RSVP_ENABLE_ATTEND_INFO || !ENABLE_SHIFT_TIME_RECEPTION) return [];

    return API_ATTEND_INFO.filter((item) => item.shift.includes(guest.shift));
  }, [guest.shift]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const { formFields, createChangeHandler, onChangeHandlerWithVal } = useFormFields({
    name: isRegistered ? guest.name : '',
    phone_number: guest.phone_number || '',
    guest_quota: guest.guest_quota || 2,
    total_guest: guest.guest_confirm || guest.guest_quota || 1,
    address: guest.address === '-' ? '' : guest.address || '',
    email: guest.email === '-' ? '' : guest.email || '',
    is_attended: guest.status === 'NOT_ATTEND' ? TYPE.NO : TYPE.YES,
    attend_info: RSVP_ENABLE_ATTEND_INFO ? guest.attend_info || filteredEventInfo[0]?.value : '',
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(guest.country_code || '62');

  const handleCountryCodeChange = useCallback((code) => {
    setSelectedCountryCode(code);
  }, []);

  const callToasterError = (desc, title = 'Oops!') => {
    toast({
      title,
      description: desc,
      status: 'error',
      isClosable: true,
    });
  };

  const callToasterSuccess = (desc, title = txt.success[lang]) => {
    toast({
      title,
      description: desc,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  /**
   * function to submit to BE with check the form value first
   */

  const onSubmitForm = async () => {
    const {
      name,
      address,
      phone_number,
      total_guest,
      is_attended,
      email,
      attend_info,
    } = formFields;
    // reset error state

    setErrorType(ERROR_TYPE);
    // define current error state
    let currentErrorType = ERROR_TYPE;

    // Make sure user already input the value
    /**
     * ERROR CHECKING
     */
    if (RSVP_ENABLE_NAME && !name) {
      currentErrorType = { ...currentErrorType, name: txt.required[lang] };
    }

    if (RSVP_ENABLE_PHONE_NUMBER) {
      if (!phone_number) {
        currentErrorType = { ...currentErrorType, phone: txt.required[lang] };
      } else if (phone_number.length > 13 || phone_number.length < 5) {
        currentErrorType = { ...currentErrorType, phone: txt.invalidPhone[lang] };
      }
    }

    if (RSVP_ENABLE_ADDRESS && !address) {
      currentErrorType = { ...currentErrorType, address: txt.required[lang] };
    }

    if (RSVP_ENABLE_EMAIL) {
      if (!email) {
        currentErrorType = { ...currentErrorType, email: txt.required[lang] };
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        currentErrorType = { ...currentErrorType, email: txt.invalidEmail[lang] };
      }
    }

    if (
      currentErrorType.address ||
      currentErrorType.email ||
      currentErrorType.name ||
      currentErrorType.phone
    ) {
      setErrorType(currentErrorType);
      return;
    }
    setIsLoading(true);
    const guestPayload = {
      name,
      address: address || '-',
      countryCode: selectedCountryCode,
      phoneNumber: phone_number || '-',
      email: email || '-',
      shift: guest.shift || '-',
      system_info: 'WARNING_Terdapat Perubahan dari User_',
      attendInfo: is_attended === TYPE.YES ? attend_info : '',
    };

    // Usecase: Group or General Invitation
    // We will `insert` instead of `update` if user doens't have code yet. But,
    // we will throw error if QR Invitation
    if (!guest.code) {
      if (ENABLE_QR_INVITATION) {
        setIsLoading(false);
        callToasterError(txt.msgError[lang]);
        return;
      }
      await onAddNewGuest({
        ...guestPayload,
        desc: guest.name || '-',
        guest_quota: guest.guest_quota,
        guest_confirm: total_guest && is_attended === TYPE.YES ? total_guest : 0,

        status: is_attended === TYPE.YES ? RSVP_STATUS.attend : RSVP_STATUS.notAttend,
        onSuccess: (guestCode) => {
          setIsLoading(false);
          callToasterSuccess(txt.msgSuccess[lang]);
          onUpdateGuest(guestCode, name);
        },
        onFailed: () => {
          setIsLoading(false);
          callToasterError(txt.msgError[lang]);
        },
      });
      return;
    }
    await onUpdateRsvp({
      ...guestPayload,
      desc: guest.desc || '-',
      isAttended: is_attended === TYPE.YES,
      totalGuest: total_guest && is_attended === TYPE.YES ? total_guest : 0,

      onSuccess: () => {
        setIsLoading(false);
        // onResetFormWithKey('phone_number');
        callToasterSuccess(txt.msgSuccess[lang]);
      },
      onFailed: () => {
        setIsLoading(false);
        callToasterError(txt.msgError[lang]);
      },
    });
  };

  return (
    <Box
      position="relative"
      height="100%" 
      padding="24px"
      className={styleRsvp}
      {...rest}
    >
        <Box 
          mx="auto" 
          bgColor={BG_LAYER} 
          border="1px solid" 
          borderColor={BG_LAYER_BORDER}
          borderRadius="16px" 
          zIndex={2} 
          css={styleRsvp}
          position="relative"
          className="rsvp-wrapper">

        {/* ASSETS */}
        <Box className="flower-left">
          <WithAnimation type="zoom">
            <Image src={ASSETS_FLOWER_3} className="flower-left-img" />
          </WithAnimation>
        </Box>
        <Box className="flower-right">
          <WithAnimation type="zoom">
            <Image src={ASSETS_FLOWER_3} className="flower-right-img" />
          </WithAnimation>
        </Box>

          {/* Title & Desc Section */}
          <Box textAlign="center">
            <WithAnimation>
              <Heading
                {...TITLE_SECTION_STYLES}
                marginTop="16px"
                fontSize="40px"
              >
                <span dangerouslySetInnerHTML={{ __html: txt.title[lang] }} />
              </Heading>
            </WithAnimation>
            <WithAnimation>
              <Text
                {...CONTENT_STYLES}
                my="16px"
                fontStyle="italic"
                dangerouslySetInnerHTML={{ __html: txt.desc[lang] }}
              />
            </WithAnimation>
          </Box>

          {/* Form Sections - Name */}
          {RSVP_ENABLE_NAME && (
            <WithAnimation>
              <FormControl margin="8px 0" isInvalid={errorType.name}>
                <FormLabel {...FORM_LABEL_PROPS} marginBottom={0}>
                  {txt.name[lang]}:
                </FormLabel>
                <Input
                  {...INPUT_COMMON_PROPS}
                  placeholder="..."
                  value={formFields.name}
                  onChange={createChangeHandler('name')}
                  disabled={isRegistered}
                />
                <FormErrorMessage {...ERROR_PROPS}>{`* ${errorType.name}`}</FormErrorMessage>
                <FormLabel {...FORM_LABEL_PROPS} fontStyle="italic" fontSize="13px" margin="8px 0 -8px 0">*) {txt.nameHelper[lang]}</FormLabel>
              </FormControl>
            </WithAnimation>
          )}
          {/* Form Sections - Phone Number */}
          <Box pos="relative" zIndex="2">
            <WithAnimation>
              <MemoPhoneNumberList
                value={String(formFields.phone_number)}
                createChangeHandler={createChangeHandler}
                lang={lang}
                selectedCountryCode={String(selectedCountryCode)}
                errorPhone={errorType.phone}
                handleCountryCodeChange={handleCountryCodeChange}
              />
            </WithAnimation>
          </Box>

          {/* Form Sections - Address */}
          <Box pos="relative" zIndex="1">
            {RSVP_ENABLE_ADDRESS && (
              <WithAnimation>
                <FormControl margin="8px 0" isInvalid={errorType.address}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.address[lang]}:</FormLabel>
                  <Textarea
                    {...INPUT_COMMON_PROPS}
                    height="40px"
                    placeholder="..."
                    value={formFields.address}
                    onChange={createChangeHandler('address')}
                  />
                  <FormErrorMessage {...ERROR_PROPS}>{'* ' + errorType.address}</FormErrorMessage>
                </FormControl>
              </WithAnimation>
            )}
            {/* Form Sections - Email */}
            {RSVP_ENABLE_EMAIL && (
              <WithAnimation>
                <FormControl margin="8px 0" isInvalid={errorType.email}>
                  <FormLabel {...FORM_LABEL_PROPS}>Email:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={formFields.email}
                    onChange={createChangeHandler('email')}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.email}</FormErrorMessage>
                </FormControl>
              </WithAnimation>
            )}

          </Box>
          {/* Form Sections - Attendance */}
          <WithAnimation>
            <FormControl margin="8px 0">
              <FormLabel {...FORM_LABEL_PROPS}>{txt.willYoutAttend[lang]}</FormLabel>
              <Box display={'flex'} justifyContent={'center'} gap={'15px'}>
                <Button
                  {...BUTTON_PADDING_PROPS}
                  borderRadius="8px"
                  fontSize="14px"
                  background={formFields.is_attended === TYPE.YES ? 'bgAlternative' : 'bgSecondary'}
                  onClick={() => {onChangeHandlerWithVal('is_attended', TYPE.YES)}}
                >
                  {txt.willAttend[lang]}
                </Button>
                <Button
                  {...BUTTON_PADDING_PROPS}
                  borderRadius="8px"
                  fontSize="14px"
                  background={formFields.is_attended === TYPE.NO ? 'bgAlternative' : 'bgSecondary'}
                  onClick={() => {onChangeHandlerWithVal('is_attended', TYPE.NO)}}
                >
                  {txt.noAttend[lang]}
                </Button>
                
              </Box>
            </FormControl>
          </WithAnimation>

          {/* ATTEND INFO SECTION */}
          {RSVP_ENABLE_ATTEND_INFO && formFields.is_attended === TYPE.YES && (
            <FormControl margin="8px 0">
              <FormLabel {...FORM_LABEL_PROPS}>{txt.attendInfo[lang]}</FormLabel>
              <Select
                {...SELECT_COMMON_PROPS}
                value={formFields.attend_info}
                onChange={createChangeHandler('attend_info')}
              >
                {filteredEventInfo.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.title}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {/* Form Sections - Partner */}
          {formFields.is_attended === TYPE.YES && (
            <MemoPersonalizeList
              lang={lang}
              totalQuota={guest.guest_quota}
              value={formFields.total_guest}
              onChange={createChangeHandler('total_guest')}
            />
          )}

          
          <WithAnimation>
            <Flex justifyContent="end">
              {ENABLE_GUEST_INFORMATION && false && (
                <Button
                  bgColor="bgPrimary"
                  marginTop="24px"
                  size="sm"
                  type="button"
                  fontFamily="body"
                  fontWeight="normal"
                  fontSize="xs"
                  color="mainColorText"
                  onClick={onOpen}
                  borderRadius="8px"
                  marginRight="8px"
                  {...BUTTON_PROPS}
                >
                  {isFamily ? 'FAMILY INFORMATION' : 'GUEST INFORMATION'}
                </Button>
              )}

              <Button
                isLoading={isLoading}
                type="button"
                margin="24px auto 0 auto"
                onClick={onSubmitForm}
                {...BUTTON_PADDING_PROPS}
              >
                {txt.submit[lang]}
              </Button>
            </Flex>
          </WithAnimation>
        </Box>

      {/* GUEST INFORMATION: hidden */}
      {false && <GuestInformation visible={isOpen} onClose={onClose} lang={lang} />}
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
